<template>
<view class="font-serif">
  <Header style="width: 100%;"/>
  <el-empty v-if="info.results.length === 0 "/>
  <div class="detail_container" v-else>
    <el-card v-for="item in info.results" :key="item.id" style="margin-bottom: 30px;" shadow="hover">
      <div class="detail" > 
      <el-image style="width: 200px; height: 200px;border-radius: 10px;" :src="item.image"></el-image>
      
      <div class="info_box">
          <div class="title text-2xl tracking-wide">{{ language ===0 ?item.name :item.cn}}</div>
          <el-divider content-position="left">{{ language ===0 ? 'Informaciones': '产品信息：'}}</el-divider>
          <div style="display: flex;margin-top: 5px; align-items: center;">
              <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Código:': '产品编号：'}}</div>
              <div class="model font-sans text-xl">{{ item.code }}</div>
          </div>
          <div style="display: flex;margin-top: 10px;">
              <div style="display: flex; margin-right: 50px;font-size: 18px;">
                  <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Ancho:': '宽：'}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.ancho }}</div>
              </div>
              <div style="display: flex; margin-right: 50px;font-size: 18px;">
                  <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Largo:': '长：'}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.largo }}</div>
              </div>
              <div style="display: flex; margin-right: 50px;font-size: 18px;">
                  <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Altura:': '高：'}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.altura }}</div>
              </div>
          </div>
          <!-- <div style="display: flex; margin-top: 10px;">
              <div class="text-gray-500" style="margin-right: 10px;">
                  Las Dimensiones del Producto:
              </div>
              <div style="font-weight: bold;" class="font-sans">{{ item.dimension }}</div>
          </div> -->
          <div style="display: flex; margin-top: 10px;">
              <div style="display: flex; margin-right: 50px;font-size: 18px;">
                  <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Material:': '产品材料：'}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.material }}</div>
              </div>
              <div style="display: flex; margin-right: 50px;font-size: 18px;">
                  <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Color:': '产品颜色：'}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.color }}</div>
              </div>
              <div style="display: flex; margin-right: 50px;font-size: 18px;">
                  <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Cantidad por Caja:': '每箱数量：'}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.quantity }}</div>
              </div>
          </div>
      </div>
      <!-- 即将上线 -->
      <div v-if="!item.is_available" class="available_box">
        {{ language ===0 ? 'Pronto': '即将上线'}}
        
      </div>
    </div>
    </el-card>
    
  </div>
  <!-- 分页 -->
  <!-- <Page :info="info"/> -->
  <div style="display: flex; justify-content: center; margin-bottom: 50px;" class="font-sans">
    <el-pagination 
      background :current-page="current" @current-change="goToPage"
      :hide-on-single-page="true"
      :pager-count="11"
      :page-size="10"
      :total="info.count"
      layout="prev, pager, next"
    />
  </div>
  <div style="height: 50px;"></div>
  <Footer style="width: 100%; position: fixed; bottom: -10px;" />
</view>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
// import Detail from '@/components/Detail.vue';
// import Page from '@/components/Page.vue';
import axios from 'axios';
export default {
    name:'ProductDetail',
    components: {
        Header,
        Footer,
        // Page
        // Detail
    },
    data:function(){
      return{
        info:{results:[]},
        current:1,
        language:0
      }
    },
    mounted(){
      this.get_product_data()
      this.current=this.getPageFromUrl()
    },
    methods:{
      get_product_data(){
        let url = '/api/web'
        // 获取路由的配置参数
        // 1. 获取页面参数
        const page =Number(this.$route.query.page);
        // 2. 获取类型参数
        const category_id = this.$route.query.category_id
        // 3. 获取语言参数
        this.language = Number(this.$route.query.language)
        if(!this.language){
          this.language = 0
        }

        const params = new URLSearchParams
        if(page){
          params.append('page',page)
        }
        else{
          this.current = 1
        }
        if (category_id){
          params.append('category_id',category_id)
        }

        url = url +"?"+params.toString()
        // 判断是否是第一页或者没有page参数
        // if (!isNaN(page) && (page !== 0)){
        //   url = url +'/?page='+page;
        // }
        // axios发送请求
        axios.get(url)
             .then(response=>{
                this.info = response.data
                window.scrollTo(0, 0);
             })
             .catch(error =>{console.log(error)})
      },
      // 跳转页面
      goToPage(page){
        this.current = page
        // 获取当前参数
        const query = {...this.$route.query};
        // 更新page参数
        query.page = page;
        // 跳转并传参数对象
        this.$router.push({
          query
        })
      },
      //获取当前页面
      getPageFromUrl(){
          const page = Number(this.$route.query.page)
          // 如果page不存在就赋值为1
          return page ? page :1
      },
    },
    watch:{
      //监听路由的变化
      $route(){
        this.get_product_data()
      }
    }
}
</script>

<style lang="scss" scoped>
.detail_container{
  padding: 20px 50px;
  width: 100%;
  margin: auto;
  .detail{
    position: relative;
    width: 100%;
    display: flex;
    // padding-bottom: 50px;
    // border-bottom: 1px solid #d4d4d4;
    .info_box{
        margin-left: 30px;
        width: 100%;
        .title{
            padding: 5px 10px;
            font-weight: bold;
        }
        .model{
            color: white;
            background-color: #25a766;
            border-radius: 5px;
            margin-left: 10px;
            padding: 3px 10px;
        }
    }
    .available_box{
      position: absolute;
      right: 0;
      // color: white;
      color: #dc2626;
      // color: #dc2626;
      padding: 5px 10px;
      border-radius: 5px;
      border: #dc2626 solid 1px;
      // background-color: #e69696;
    }
  }
}
</style>

<style>
.el-pagination.is-background .el-pager li.is-active {
  background-color: #dc2626;
}

</style>